import {alpha, Tooltip, Typography} from '@mui/material'
import ExaminationToEmployee, {
  submissionStatusEnum
} from 'src/entities/ExaminationToEmployee'

export default function PassingTestView({
  currentTest
}: {
  currentTest?: ExaminationToEmployee
}) {
  const color = currentTest?.isPassingScore ? '#4caf50' : '#dd2c00'
  return (
    <>
      {currentTest &&
        currentTest.submissionStatus !== submissionStatusEnum.Lack && (
          <Tooltip
            title={`${
              !currentTest.isPassingScore ? 'not ' : ''
            }a passing grade`}
          >
            <Typography
              variant="button"
              px={1}
              borderRadius={0.5}
              fontSize={12}
              width={60}
              color={color}
              bgcolor={alpha(color, 0.1)}
            >
              {`${currentTest.score ?? 0}/${currentTest.totalQuestions}`}
            </Typography>
          </Tooltip>
        )}
    </>
  )
}
