import {Form} from 'src/entities/Form'
import Track from 'src/entities/Track'

export default interface ExaminationToEmployee {
  examinationID: number
  examinationOrder: number
  form?: Form
  formResultContent?: string
  examinationUrl: string
  track: Track
  isLock?: boolean
  isWinnerLotery?: boolean
  loteryTicketsNumber?: number
  submissionStatus?: submissionStatusEnum
  isPassingScore?: boolean
  score?: number
  totalQuestions?: number
}

export enum submissionStatusEnum {
  OnTime = 1,
  Late,
  Lack,
  'on time' = OnTime,
  'late' = Late,
  'lack' = Lack
}
